<template>
  <ShadowCard :param="{ name: 'Orders', showButtons: true }">
    <orders />
  </ShadowCard>
</template>
<script>
import ShadowCard from "../../../presentationalComponents/ShadowCard.vue";
import orders from "./orders.vue";

export default {
  name: "ordersWrapper",
  components: { orders, ShadowCard },
};
</script>
